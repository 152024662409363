import Axios from "axios";
import { userStore } from "../storage/user/User";
import { ConfigService } from "../configuration/configuration.service";

type HttpMethod = "GET" | "POST" | "PUT";

export default class Jira {
    private static BASE_URL: string = "";

    static initialize() {
        if (Jira.BASE_URL) return;
        const configService = ConfigService.getInstance();
        Jira.BASE_URL = configService.serverUrl || "";
    }

    private static async call(
        endpoint: string,
        method: HttpMethod = "GET",
        data: any = {}
    ) {
        try {
            if (!Jira.BASE_URL) {
                throw new Error("Jira API not initialized. Call Jira.initialize() before using it.");
            }

            const token = userStore.token;
            if (!token) {
                throw new Error("User token is not available.");
            }

            const response = await Axios({
                method,
                url: `${Jira.BASE_URL}${endpoint}`,
                data,
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error in Jira request:", error);
            throw error;
        }
    }

    static async getGadget(gadgetName: string): Promise<any> {
        return this.call(`data/gadget/${gadgetName}`);
    }

    static async getResource(resourceName: string, params: string = "") {
        return this.call(`data/resource?resource=${resourceName}&${params}`);
    }

    static async postResource(resourceName: string, data: any, params: string = "") {
        return this.call(`data/resource?resource=${resourceName}&${params}`, "POST", data);
    }

    static async putResource(resourceName: string, data: any, params: string = "") {
        return this.call(`data/resource?resource=${resourceName}&${params}`, "PUT", data);
    }
}

Jira.initialize();
