export class ConfigService {
  private static instance: ConfigService | null = null;
  private _serverUrl: string | undefined = '';

  private constructor() {
    this.loadEnvVariables();
  }

  static getInstance(): ConfigService {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }
    return ConfigService.instance;
  }

  private loadEnvVariables(): void {
    if (process.env.NODE_ENV === 'development') {
      this._serverUrl = process.env.REACT_APP_SERVER_BASE_URL_DEV;
    } else {
      this._serverUrl = process.env.REACT_APP_SERVER_BASE_URL_PROD;
    }

    if (!this._serverUrl) {
      console.error('Server URL could not be loaded.');
    }
  }

  get serverUrl(): string {
    if (!this._serverUrl) {
      throw new Error('Server URL has not been initialized.');
    }
    return this._serverUrl;
  }
}
