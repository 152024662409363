import { redirect } from './history';
import { userStore } from '../storage/user/User';
import { ConfigService } from '../configuration/configuration.service';

export default class Authentication {
    static BASE_URL: string;

    static initialize() {
        const configService = ConfigService.getInstance();
        Authentication.BASE_URL = configService.serverUrl;
    }

    /*
     * Will redirect to the OAuth route.
    */
    static oauth() {
        if (!Authentication.BASE_URL) {
            console.error('BASE_URL is not defined.');
            return;
        }
        redirect(`${Authentication.BASE_URL}authentication/connect`);
    }

    static logout() {
        userStore.setUser({});
        userStore.setToken("");
    }
}

Authentication.initialize();
